import React from "react";
import { PageProps } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";

import * as css from "./therapeutics.module.scss";

import BloodDropIcon from "../images/icon-blood-drop.svg";
import FractureIcon from "../images/icon-fracture.svg";
import SkinGrowthIcon from "../images/icon-skin-growth.svg";
import SkinRefreshIcon from "../images/icon-skin-refresh.svg";
import BeautyMaskIcon from "../images/icon-beauty-mask.svg";

import { Page } from "../layout/page";
import { HeaderMergedContent } from "../layout/header_merged_content";
import { AnimateIn } from "../layout/animate_in";
import { Parallax } from "../layout/parallax";

export default function IndexPage(props: PageProps) {
  return (
    <Page menuId="therapeutics" title="Therapeutics">
      <HeaderMergedContent>
        <div className={css.pageHead}>
          <h2>Therapeutics</h2>
          <hr />
          <div className={css.intro}>
            Efficient and non-invasive skin drug delivery technologies eliminate the adverse side
            effects. This opens up a world of possibilities for new skin therapeutics.
          </div>
          <ul className={css.toc}>
            <li>
              <FractureIcon className={css.icon} />
              Chronic Pain &amp; Inflammation
            </li>
            <li>
              <BeautyMaskIcon className={css.icon} />
              Skin Regeneration
            </li>
            <li>
              <SkinRefreshIcon className={css.icon} />
              Atopic Dermatitis
            </li>
            <li>
              <SkinGrowthIcon className={css.icon} />
              Topical Anesthetics
            </li>
            <li>
              <BloodDropIcon className={css.icon} />
              Cannabis
            </li>
          </ul>
        </div>
      </HeaderMergedContent>

      <AnimateIn className={css.section} direction="up">
        <div className={css.left}>
          <FractureIcon className={css.icon} />
          <h3>Chronic Pain &amp; Inflammation</h3>
          <div className={css.content}>
            Pain medications, particularly those used for long-term chronic pain management, can
            have serious and potentially life-threatening adverse effects. As patient ages and their
            lists of comorbid disease states grow, medication management becomes increasingly more
            complex and options for pharmacological interventions diminish. For this reason,
            topically applied and localized drug effects may be favorable. However, local delivery
            of anti-inflammatory drugs such as NSAIDs and narcotic analgesics present challenges due
            to low skin permeability and formulation instability in the presence of high active
            medicine concentrations. CNano’s advanced nano drug delivery technology addresses both
            these challenges and provides an advanced manufacturing platform for developing local
            anti-inflammatory and analgesic drugs.
          </div>
        </div>
        <div className={css.right}>
          <Parallax>
            <StaticImage
              objectFit="contain"
              className={css.img}
              src="../images/thera-b-pain.png"
              alt=""
            />
          </Parallax>
        </div>
      </AnimateIn>
      <AnimateIn className={css.section} direction="left">
        <div className={css.left}>
          <BeautyMaskIcon className={css.icon} />
          <h3>Skin Regeneration</h3>
          <div className={css.content}>
            Natural skin repair is rarely achievable without scar formation. Bioactive peptides and
            proteins are practical tools for regulating cellular processes and have been applied to
            control cellular differentiation and tissue regeneration. However, topical application
            of those molecules is challenging due to low skin permeability. Addressing both
            challenges through enhanced dermal peptide permeability, CNano’s innovative technology
            enables passage through the epidermis and retention in the dermis, thereby providing an
            advanced manufacturing platform for the development of anti-scar and anti-aging
            medicine.
          </div>
        </div>
        <div className={css.left}>
          <Parallax>
            <StaticImage className={css.img} src="../images/thera-b-skin.jpg" alt="" />
          </Parallax>
        </div>
      </AnimateIn>
      <AnimateIn className={css.section} direction="right">
        <div className={css.left}>
          <SkinRefreshIcon className={css.icon} />
          <h3>Atopic Dermatitis</h3>
          <div className={css.content}>
            Eczema (Atopic Dermatitis) is a chronic, relapsing, non-contiguous skin condition, which
            represents a complex, multi-factorial disorder due to an impairment of the stratum
            corneum barrier. It is very common in children with sensitive skin. Currently available
            drugs have a low skin bioavailability and may give rise to severe adverse events.
            CNano’s advanced nano drug delivery technology using a low concentration of emulsifiers
            offers unprecedented solutions to these issues, enabling: i) the management of atopic
            dermatitis in adults and children with sensitive skin, ii) enhanced skin barrier
            function, iii) increased duration of action and enhanced patient adherence, iv) improved
            safety profile.
          </div>
        </div>
        <div className={css.right}>
          <Parallax>
            <StaticImage className={css.img} src="../images/thera-b-atopic.jpg" alt="" />
          </Parallax>
        </div>
      </AnimateIn>
      <AnimateIn className={css.section} direction="left">
        <div className={css.left}>
          <SkinGrowthIcon className={css.icon} />
          <h3>Topical Anesthetics</h3>
          <div className={css.content}>
            Local anesthetic drugs are widely used for minor operations in adults and children.
            Depth of anesthesia and effectiveness depends on the contact time with local
            anesthetics, combinations of anesthetic drugs and the concentration. However the current
            formulations do not allow a high concentration of those molecules in salt form. CNano’s
            robust sustained release nano transdermal technology allows formulation with a
            combination of anesthetic drugs as high as 30% (w/w) over a 24 hour period.
          </div>
        </div>
        <div className={css.right}>
          <Parallax>
            <StaticImage className={css.img} src="../images/thera-b-anesthetics.jpg" alt="" />
          </Parallax>
        </div>
      </AnimateIn>
      <AnimateIn className={css.section} direction="right">
        <div className={css.left}>
          <BloodDropIcon className={css.icon} />
          <h3>Cannabis</h3>
          <div className={css.content}>
            Medical Cannabis has seen rapid adoption for pain management and anti-inflammatory
            applications, and this trend is expected to continue. CNano’s novel solution provides
            the technology platform for development of long acting transdermal Cannabis drug
            delivery systems that produce medicinal benefits without the psychoactive/cognitive side
            effects, thereby overcoming the primary limiting factor for spurring significant growth
            in this sector.
          </div>
        </div>
        <div className={css.right}>
          <Parallax>
            <StaticImage className={css.img} src="../images/thera-b-cannabis.jpg" alt="" />
          </Parallax>
        </div>
      </AnimateIn>
    </Page>
  );
}

import React, { FunctionComponent, useEffect, useRef, useState } from "react";

import * as css from "./animate_in.module.scss";

interface Props {
  className?: string;
  direction?: "up" | "down" | "right" | "left";
}

export const AnimateIn: FunctionComponent<Props> = (props) => {
  const containerRef = useRef<HTMLDivElement>();
  const [isVisible, setIsVisible] = useState(false);
  useEffect(() => {
    if (!containerRef.current) return;

    const observer = new IntersectionObserver((entries) => {
      if (entries.length > 0 && entries[0].isIntersecting) {
        setIsVisible(true);
      }
    }, {});
    observer.observe(containerRef.current);

    return () => {
      observer.disconnect();
    };
  }, [containerRef.current]);

  const direction = props.direction ?? "up";

  return (
    <div
      ref={containerRef}
      className={`${css.animateIn} ${css["dir" + direction]} ${isVisible ? css.visible : ""} ${
        props.className
      }`}
    >
      {props.children}
    </div>
  );
};

// extracted by mini-css-extract-plugin
export var animateIn = "animate_in-module--animate-in--MvHnj";
export var dirdown = "animate_in-module--dirdown--eMwpQ";
export var dirleft = "animate_in-module--dirleft--4VQBy";
export var dirright = "animate_in-module--dirright--O3D4b";
export var dirup = "animate_in-module--dirup--sO5QP";
export var fadeInBottom = "animate_in-module--fade-in-bottom--IQtwz";
export var fadeInLeft = "animate_in-module--fade-in-left--TbCP9";
export var fadeInRight = "animate_in-module--fade-in-right--O7Gep";
export var fadeInTop = "animate_in-module--fade-in-top--CgpjF";
export var visible = "animate_in-module--visible--Xt5Do";
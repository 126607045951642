import React, { FunctionComponent, useEffect, useRef, useState } from "react";

import * as css from "./parallax.module.scss";

interface Props {
  className?: string;
  z?: number;
  mz?: number;
}

export const Parallax: FunctionComponent<Props> = (props) => {
  const containerRef = useRef<HTMLDivElement>();
  const mouseX = useRef(0.5);
  const mouseY = useRef(0.5);

  const [parralaxX, setParralaxX] = useState(0);
  const [parralaxY, setParralaxY] = useState(0);

  useAnimationFrame((time) => {
    const containerEl = containerRef.current;
    if (!containerEl) return;

    const rect = containerEl.getBoundingClientRect();
    const y = (rect.top + rect.bottom) / 2;
    const height = document.body.clientHeight;
    let px = (mouseX.current - 0.5) * (props.mz ?? 0);
    let py =
      (mouseY.current - 0.5) * (props.mz ?? 0) +
      (Math.max(0, Math.min(1, y / height)) - 0.5) * (props.z ?? 1);

    setParralaxX(px);
    setParralaxY(py);
  });

  useEffect(() => {
    const onMouseMove = (e: MouseEvent) => {
      mouseX.current = e.clientX / document.body.clientWidth;
      mouseY.current = e.y / document.body.clientHeight;
    };
    document.addEventListener("mousemove", onMouseMove);

    return () => {
      document.removeEventListener("mousemove", onMouseMove);
    };
  });

  const px = parralaxX * 100;
  const py = parralaxY * 100;
  const containerStyle = {
    transform: `translate(${px}px, ${py}px)`,
  };

  return (
    <div style={containerStyle} ref={containerRef} className={`${css.parallax} ${props.className}`}>
      {props.children}
    </div>
  );
};

function useAnimationFrame(callback: FrameRequestCallback) {
  const rafRef = useRef<number>();
  useEffect(() => {
    const cb = (time) => {
      callback(time);
      rafRef.current = requestAnimationFrame(cb);
    };
    rafRef.current = requestAnimationFrame(cb);
    return () => {
      cancelAnimationFrame(rafRef.current);
    };
  }, []);
}
